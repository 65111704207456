import {JsonObject, JsonProperty} from 'json2typescript';
import {Collection} from '@app/order-assortment/model/collection/collection';
import {Comparable} from '@app/core/services/comparable';
import {isNil} from 'lodash';

@JsonObject('Collection')
export class CollectionImp extends Comparable<Collection> implements Collection {

  @JsonProperty('id', String)
  private _id: string = undefined;

  @JsonProperty('name', String)
  private _name: string = undefined;

  @JsonProperty('order', Number, true)
  private _order: number = undefined;

  @JsonProperty('id', String)
  private _label: string = undefined;


  constructor(id?: string, name?: string, order?: number) {
    super();
    this._id = id;
    this._name = name;
    this._order = order;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get order (): number {
    return this._order;
  }

  set order (value: number) {
    this._order = value;
  }

  get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }

  equals(otherCollection: Collection) {
    if (isNil(otherCollection)) {
      return false;
    }
    return this.id === otherCollection.id && this.name === otherCollection.name;
  }

}
