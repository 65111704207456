import {JsonObject, JsonProperty} from 'json2typescript';
import {ProductColor} from '@app/order-assortment/model/product-color/productColor';
import {Drop} from '@app/drop/model/drop';
import {DropImp} from '@app/drop/model/drop.imp';
import {Family, FamilyImp} from '@app/order-assortment/model/family';
import {Line, LineImp} from '@app/order-assortment/model/line';
import {Territory, TerritoryImp} from '@app/order-assortment/model/territory';
import {Collection, CollectionImp} from '@app/order-assortment/model/collection';
import {Comparable} from '@app/core/services/comparable';
import {isNil} from 'lodash';

@JsonObject('ProductColor')
export class ProductColorImp extends Comparable<ProductColor> implements ProductColor {

  @JsonProperty('id', String)
  private _id: string = undefined;

  @JsonProperty('keyBet', Boolean)
  private _keyBet: boolean = undefined;

  @JsonProperty('productId', String)
  private _productId: string = undefined;

  @JsonProperty('wsPrice', Number)
  private _wsPrice: number = undefined;

  @JsonProperty('qty', Number)
  private _qty: number = undefined;

  @JsonProperty('thumbnails', [String])
  private _thumbnails: string[] = undefined;

  @JsonProperty('drop', DropImp)
  private _drop: Drop = undefined;

  @JsonProperty('family', FamilyImp)
  private _family: Family = undefined;

  @JsonProperty('line', LineImp)
  private _line: Line = undefined;

  @JsonProperty('territory', TerritoryImp)
  private _territory: Territory = undefined;

  @JsonProperty('collection', CollectionImp)
  private _collection: Collection = undefined;

  @JsonProperty('soldQty', Number)
  private _soldQty: number = undefined;

  @JsonProperty('available', [String], true)
  private _availableColors: string[] = undefined;

  private _amount: number;

  @JsonProperty('extended', Boolean)
  private _extended: boolean = undefined;

  constructor(id?: string, keyBet?: boolean, productId?: string, wsPrice?: number, qty?: number, thumbnails?: string[], drop?: Drop, family?: Family, line?: Line, territory?: Territory, collection?: Collection, soldQty?: number, availableColors?: string[], amount?: number, extended?: boolean) {
    super();
    this._id = id;
    this._keyBet = keyBet;
    this._productId = productId;
    this._wsPrice = wsPrice || 0;
    this._qty = qty || 0;
    this._thumbnails = thumbnails;
    this._drop = drop || new DropImp();
    this._family = family || new FamilyImp();
    this._line = line || new LineImp();
    this._territory = territory || new TerritoryImp();
    this._collection = collection || new CollectionImp();
    this._soldQty = soldQty || 0;
    this._availableColors = availableColors || [];
    this._amount = this._wsPrice * this._qty;
    this._extended = extended;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get keyBet(): boolean {
    return this._keyBet;
  }

  set keyBet(value: boolean) {
    this._keyBet = value;
  }

  get productId(): string {
    return this._productId;
  }

  set productId(value: string) {
    this._productId = value;
  }

  get wsPrice(): number {
    this._amount = this._qty * this._wsPrice;
    return this._wsPrice;
  }

  set wsPrice(value: number) {
    this._wsPrice = value;
  }

  get qty(): number {
    this._amount = this._qty * this._wsPrice;
    return this._qty;
  }

  set qty(value: number) {
    this._qty = value;
  }

  get thumbnails(): string[] {
    return this._thumbnails;
  }

  set thumbnails(value: string[]) {
    this._thumbnails = value;
  }

  get drop(): Drop {
    return this._drop;
  }

  set drop(value: Drop) {
    this._drop = value;
  }

  get family(): Family {
    return this._family;
  }

  set family(value: Family) {
    this._family = value;
  }

  get line(): Line {
    return this._line;
  }

  set line(value: Line) {
    this._line = value;
  }

  get territory(): Territory {
    return this._territory;
  }

  set territory(value: Territory) {
    this._territory = value;
  }

  get collection(): Collection {
    return this._collection;
  }

  set collection(value: Collection) {
    this._collection = value;
  }

  get soldQty(): number {
    return this._soldQty;
  }

  set soldQty(value: number) {
    this._soldQty = value;
  }

  get availableColors(): string[] {
    return this._availableColors;
  }

  set availableColors(value: string[]) {
    this._availableColors = value;
  }

  get amount(): number {
    return this._qty * this._wsPrice;
  }

  set amount(value: number) {
    this._amount = value;
  }

  get extended(): boolean {
    return this._extended;
  }

  set extended(value: boolean) {
    this._extended = value;
  }

  equals(otherProductColor: ProductColor) {
    if (isNil(otherProductColor)) {
      return false;
    }
    return this.id === otherProductColor.id &&
      this.keyBet === otherProductColor.keyBet &&
      this.extended === otherProductColor.extended &&
      this.productId === otherProductColor.productId &&
      this.qty === otherProductColor.qty &&
      this.thumbnails === otherProductColor.thumbnails &&
      this.wsPrice === otherProductColor.wsPrice;
  }
}
