<div *ngIf="notification" class="ui-messages ui-widget d-block p-1 m-0 border-0"
     [ngClass]="{'ui-messages-error': notification?.type === type.ERROR,
                'ui-messages-warn': notification?.type === type.WARNING,
                'ui-messages-success': notification?.type === type.SUCCESS}">

  <div class="d-flex align-items-center px-2 py-3">
    <span class="ui-tabview-right-icon icon-circle-dgl fa-close m-0"></span>
    <p class="ui-messages-detail mb-0 pl-3"><strong>{{notification.message}}</strong></p>
  </div>
</div>
