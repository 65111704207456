import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {share} from 'rxjs/operators';

@Injectable()
export class GlobalSpinnerService {
  private spinnerObservable: Observable<any>;
  private spinnerObserver: Observer<any>;

  constructor() {
    this.spinnerObservable = Observable.create((observer: Observer<any>) => {
      this.spinnerObserver = observer;
    }).pipe(share());
  }

  showGlobalSpinner(value: any) {
    this.spinnerObserver.next(value);
  }

  onGlobalSpinner(callback: (passedValue: any) => void) {
    this.spinnerObservable.subscribe(callback);
  }

}
