import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/operators';
import {LanguageService} from '@app/shared/language/services/language.service';

@Component({
  selector: 'dgl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private route: ActivatedRoute, private languageService: LanguageService) {
    const queryParamLangSubscription = this.route.queryParams.pipe(
      filter(params => params['lang'])
    ).subscribe(params => {
      this.languageService.change(params.lang);
      if (queryParamLangSubscription) {
        queryParamLangSubscription.unsubscribe();
      }
    });
  }
}
