import {Inject, Injectable} from '@angular/core';
import {throwError, Observable, BehaviorSubject} from 'rxjs';
import {map, catchError, finalize} from 'rxjs/operators';

import {LocalStorage, LocalStorageService, SessionStorage, SessionStorageService} from 'ngx-webstorage';
import {AuthenticationService} from '@app/core/authentication';
import {Principal} from '@app/core/authentication/model/principal';
import {
  AUTHENTICATION_MODULE_CONFIG,
  BASIC_AUTHENTICATION_MODULE_CONSTANTS
} from '@app/core/authentication/authentication.module.config';
import {BasicAuthenticationRemote} from '@app/core/authentication/basic/auth/basic-authentication.remote';
import {BasicPrincipalImp} from '@app/core/authentication/model/basic-principal-imp';
import {BasicPrincipal} from '@app/core/authentication/model/basic-principal';

@Injectable()
export class BasicAuthenticationService implements AuthenticationService {

  private _principal: BasicPrincipalImp;
  private _onUserLogin: BehaviorSubject<any> = new BehaviorSubject(null);
  private _onUserLogout: BehaviorSubject<any> = new BehaviorSubject(null);

  @SessionStorage(BASIC_AUTHENTICATION_MODULE_CONSTANTS.AUTH_SERVICE.PRINCIPAL_STORAGE_KEY)
  storedPrincipal;
  @LocalStorage(BASIC_AUTHENTICATION_MODULE_CONSTANTS.AUTH_SERVICE.AUTHORIZATION_STORAGE_KEY)
  authorization;

  constructor(@Inject(AUTHENTICATION_MODULE_CONFIG) private config,
              private authenticationRemote: BasicAuthenticationRemote,
              private sessionStorageService: SessionStorageService,
              private localStorage: LocalStorageService) {
  }

  login<Any>(username: string, password: string): Observable<Principal> {
    return this.authenticationRemote.login(username, password).pipe(
      map((res: any) => {
        if (res && res.item && res.item.length > 0) {
          this._principal = this.storageToPrincipal(res.item[0]);
          this.storedPrincipal = this.principalToStorage();
          this.setAuthorization(username, password);
          this._onUserLogin.next(this._principal);
          return this._principal;
        } else {
          this.logout().subscribe();
        }
      }),
      catchError((error) => {
        this.logout().subscribe();
        return throwError(error);
      }));
  }

  logout(): Observable<void> {
    return this.authenticationRemote
      .logout(this.authorization).pipe(
        catchError((error) => {
          return throwError(error);
        }),
        finalize(() => {
          this._principal = null;
          this.removeStoredCredentials();
          this._onUserLogout.next(null);
        }));
  }

  isLoggedIn(): boolean {
    return (this.authorization !== null);
  }

  onLogin<T>(): Observable<T> {
    return this._onUserLogin.asObservable();
  }

  onLogout<T>(): Observable<T> {
    return this._onUserLogout.asObservable();
  }

  getPrincipal(): Principal {
    if (!this._principal && this.storedPrincipal) {
      this._principal = this.storageToPrincipal(this.storedPrincipal);
    }
    return this._principal;
  }

  private formatAuthBasic(username: string, password: string) {
    return btoa(`${username}:${password}`);
  }

  private removeStoredCredentials() {
    this.sessionStorageService.clear(BASIC_AUTHENTICATION_MODULE_CONSTANTS.AUTH_SERVICE.PRINCIPAL_STORAGE_KEY);
    this.localStorage.clear(BASIC_AUTHENTICATION_MODULE_CONSTANTS.AUTH_SERVICE.AUTHORIZATION_STORAGE_KEY);
  }

  private storageToPrincipal(storedUserDetails: any): BasicPrincipalImp {
    return new BasicPrincipalImp(
      (storedUserDetails as BasicPrincipal).sessionId,
      (storedUserDetails as BasicPrincipal).username,
      (storedUserDetails as BasicPrincipal).roles
    );
  }
  private setAuthorization(username: string, password: string): void {
    this.authorization = this.formatAuthBasic(username, password);
    this.localStorage.store(BASIC_AUTHENTICATION_MODULE_CONSTANTS.AUTH_SERVICE.AUTHORIZATION_STORAGE_KEY, this.authorization);
  }

  private principalToStorage(): any {
    return {
      sessionId: this._principal.sessionId,
      username: this._principal.username,
      roles: this._principal.roles
    };
  }

}
