import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AuthenticationRemote} from '@app/core/authentication';
import {
  AUTHENTICATION_MODULE_CONFIG
} from '@app/core/authentication/authentication.module.config';

@Injectable()
export class BasicAuthenticationRemote implements AuthenticationRemote {

  constructor(@Inject(AUTHENTICATION_MODULE_CONFIG) private config, private http: HttpClient) {
  }

  login(username: string, password: string): Observable<any> {
    const body = new HttpParams()
      .set(this.config.AUTH_REMOTE.LOGIN_BODY_USERNAME_FIELD, username)
      .set(this.config.AUTH_REMOTE.LOGIN_BODY_PASSWORD_FIELD, password);

    const headers = new HttpHeaders()
      .set(this.config.AUTH_REMOTE.HEADERS_CONTENT_TYPE_KEY, this.config.AUTH_REMOTE.HEADERS_CONTENT_TYPE_VALUE)
      .set(this.config.AUTH_REMOTE.HEADERS_CHARSET_KEY, this.config.AUTH_REMOTE.HEADERS_CHARSET_VALUE);

    return this.http.post(this.config.AUTH_REMOTE.LOGIN_ENDPOINT, body, {headers: headers});
  }

  logout(authorization: string): Observable<any> {
    const body = new HttpParams();
    const headers = new HttpHeaders()
      .set(this.config.AUTH_REMOTE.HEADERS_AUTHORIZATION_KEY, authorization)
      .set(this.config.AUTH_REMOTE.HEADERS_CONTENT_TYPE_KEY, this.config.AUTH_REMOTE.HEADERS_CONTENT_TYPE_VALUE)
      .set(this.config.AUTH_REMOTE.HEADERS_CHARSET_KEY, this.config.AUTH_REMOTE.HEADERS_CHARSET_VALUE);

    return this.http.post(this.config.AUTH_REMOTE.LOGOUT_ENDPOINT, body, {headers: headers});
  }
}
