import {Injectable} from '@angular/core';
import {Angulartics2DglGoogleTagManager} from '@app/shared/analytics/services/angulartics2-dgl-gtm';
import {AnalyticsEvent} from '@app/shared/analytics/models/analytics-event';

@Injectable()
export class DialogAnalyticsService {

  constructor(private angulartics2DglGoogleTagManager: Angulartics2DglGoogleTagManager) {
  }

  trackEvent(event: AnalyticsEvent) {
    this.angulartics2DglGoogleTagManager.eventTrack(event.action, event);
  }
}
