import {InjectionToken} from '@angular/core';
import {DialogOptions} from '@app/shared/dialogs/model/dialog-options.model';

export interface DialogModuleConfig {
  CONFIRMATION: {
    DEFAULT: {
      HEADER_KEY: string,
      MESSAGE_KEY: string,
      ACCEPT_KEY: string,
      REJECT_KEY: string
    }
  };
  DIALOG: {
    DEFAULT: DialogOptions
  };
}

export const DIALOG_MODULE_CONSTANTS: DialogModuleConfig = {
  CONFIRMATION: {
    DEFAULT: {
      HEADER_KEY: 'confirmation.header',
      MESSAGE_KEY: 'confirmation.message',
      ACCEPT_KEY: 'app.accept',
      REJECT_KEY: 'app.cancel'
    }
  },
  DIALOG: {
    DEFAULT: {
      width: '500',
      height: 'auto',
      draggable: false,
      resizable: false,
      contentStyle: {
        overflow: 'visible'
      },
      modal: true,
      closeOnEscape: true,
      dismissableMask: true,
      closable: true,
      showHeader: true
    }
  }
};

export let DIALOG_MODULE_CONFIG = new InjectionToken<DialogModuleConfig>('dialog.module.config');
