import {GlobalSpinnerService} from './services/globalspinner.service';
import {SPINNER_MODULE_CONFIG, SPINNER_MODULE_CONSTANTS} from './spinner.config';
import {DglSpinnerDirective} from './directives/spinner.directive';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {GlobalSpinnerComponent} from './components/global-spinner.component';

@NgModule({
  declarations: [
    DglSpinnerDirective,
    GlobalSpinnerComponent
  ],
  exports: [
    DglSpinnerDirective,
    GlobalSpinnerComponent
  ]
})
export class SpinnerModule {
  public static forRoot(): ModuleWithProviders<SpinnerModule> {
    return {
      ngModule: SpinnerModule,
      providers: [
        {provide: SPINNER_MODULE_CONFIG, useValue: SPINNER_MODULE_CONSTANTS},
        GlobalSpinnerService,
      ]
    };
  }
}
