import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Subscription} from 'rxjs';
import {DialogOptions} from '@app/shared/dialogs/model/dialog-options.model';
import {DIALOG_MODULE_CONFIG} from '@app/shared/dialogs/dialog.module.config';
import {Dialog} from '@app/shared/dialogs/model/dialog.model';
import * as _ from 'lodash';
import {DialogAnalyticsService} from '@app/shared/dialogs/services/analytics/dialog.analytics.service';
import {DialogState} from '@app/shared/dialogs/model';
import {DialogAnalyticsEvents} from '@app/shared/dialogs/model/dialog-analytics-events';

@Component({
  selector: 'dgl-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],

})
export class DialogComponent implements OnDestroy, AfterViewInit {

  display = false;
  dialogHeader: String;
  dialogConfig: DialogOptions;

  analyticsEventData: DialogAnalyticsEvents;
  trackAnalyticsEvents = false;

  onShowCallback: Function;
  onHideCallback: Function;
  private _currentDialog: Dialog;

  private _componentRef: ComponentRef<any>;
  private _subscriptions: Subscription = new Subscription();

  @ViewChild('dialogBody', {read: ViewContainerRef}) dialogBodyContainer: ViewContainerRef;

  constructor(@Inject(DIALOG_MODULE_CONFIG) private config,
              private componentResolver: ComponentFactoryResolver,
              private dialogState: DialogState,
              private ref: ChangeDetectorRef,
              private dialogAnalyticsService: DialogAnalyticsService) {

    this.dialogConfig = _.cloneDeep(this.config.DIALOG.DEFAULT);
  }

  ngAfterViewInit() {
    this._subscriptions.add(this.dialogState.onCurrentDialogChanges().subscribe((dialog: Dialog) => {
      this.resetDialog();
      this._currentDialog = dialog;
      if (dialog) {
        this.setDialogOptions(dialog.dialogOptions);
        this.buildDialogBody(dialog.body);
        this.dialogHeader = dialog.header;
        this.onShowCallback = dialog.onShowCallback;
        this.analyticsEventData = dialog.analyticsEventData;
        this.trackAnalyticsEvents = dialog.trackAnalyticsEvents;
        this.ref.detectChanges();
        this.display = true;
      }
    }));
  }

  private resetDialog() {
    this.display = false;
    this.dialogHeader = '';
    this.dialogBodyContainer.clear();
    this.dialogConfig = _.cloneDeep(this.config.DIALOG.DEFAULT);
    this.onShowCallback = undefined;
  }

  private setDialogOptions(dialogOptions: DialogOptions) {
    _.merge(this.dialogConfig, dialogOptions);
  }

  private buildDialogBody(body: any) {
    if (body) {
      if (body instanceof TemplateRef) {
        this.dialogBodyContainer.createEmbeddedView(body);
      } else {
        const factory: ComponentFactory<any> = this.componentResolver.resolveComponentFactory(body);
        this._componentRef = this.dialogBodyContainer.createComponent(factory);
      }
    }
  }

  onShow() {
    this.onHideCallback = this._currentDialog ? this._currentDialog.onHideCallback : undefined;
    if (this.onShowCallback) {
      this.onShowCallback();
    }
    if (this.trackAnalyticsEvents) {
      if (this.analyticsEventData.open) {
        this.dialogAnalyticsService.trackEvent(this.analyticsEventData.open);
      }
      if (this.analyticsEventData.openVirtualPage) {
        this.dialogAnalyticsService.trackEvent(this.analyticsEventData.openVirtualPage);
      }
    }
  }

  onHide() {
    if (this.onHideCallback) {
      this.onHideCallback();
    }
    if (this.trackAnalyticsEvents && this.analyticsEventData.close) {
      this.dialogAnalyticsService.trackEvent(this.analyticsEventData.close);
    }
  }

  ngOnDestroy() {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
    }
    if (this._componentRef) {
      this._componentRef.destroy();
    }
  }
}
