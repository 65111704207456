import {Inject, Injectable} from '@angular/core';
import {User} from '@app/user/model/user';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class UserState {

  private _currentUser: User;
  currentUser$ = new BehaviorSubject<User>(this.currentUser);

  constructor() {
  }

  get currentUser(): User {
    return this._currentUser;
  }

  set currentUser(user: User) {
    this._currentUser = user;
    this.currentUser$.next(this._currentUser);
  }

  removeCurrentUser() {
    this._currentUser = undefined;
    this.currentUser$.next(this._currentUser);
  }
}
