import {Observable} from 'rxjs';
import {Principal} from '@app/core/authentication/model/principal';

export abstract class AuthenticationService {
  abstract login<Any>(username: string, password: string): Observable<any>;
  abstract logout();
  abstract isLoggedIn(): boolean;
  abstract onLogin<T>(): Observable<T>;
  abstract onLogout<T>(): Observable<T>;
  abstract getPrincipal(): Principal;
}
