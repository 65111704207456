import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from '@app/core/authentication/guard/logged/auth.guard';
import {AuthGuestGuard} from '@app/core/authentication';
import {APP_CONSTANTS} from '@app/app.config';

const routes: Routes = [
  {
    path: APP_CONSTANTS.ROUTING.VIEW.LOGIN,
    canActivate: [AuthGuestGuard],
    canLoad: [AuthGuestGuard],
    loadChildren: () => import( '@app/login/login.module').then(x => x.LoginModule)
  },
  {
    path: APP_CONSTANTS.ROUTING.VIEW.HOME,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import( '@app/main/main.module').then(x => x.MainModule)
  },
  // Fallback when no prior route is matched
  {path: '**', redirectTo: APP_CONSTANTS.ROUTING.VIEW.HOME, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
