import {GlobalSpinnerService} from '../services/globalspinner.service';
import {Component} from '@angular/core';

@Component({
  selector: 'dgl-global-spinner',
  templateUrl: './global-spinner.component.html',
  styleUrls: ['./global-spinner.component.scss']
})
export class GlobalSpinnerComponent {

  value: any;

  constructor(private globalSpinnerService: GlobalSpinnerService) {
    this.globalSpinnerService.onGlobalSpinner(serviceValue => this.value = serviceValue);
  }

}
