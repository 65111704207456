import {InjectionToken} from '@angular/core';
import {APP_CONSTANTS} from '@app/app.config';

export interface AuthenticationModuleConfig {
  AUTH_HTTP: any;
  AUTH_REMOTE: any;
  AUTH_SERVICE: any;
  AUTH_GUARD: any;
}

export const COMMON_AUTHENTICATION_MODULE_CONSTANTS: AuthenticationModuleConfig = {
  AUTH_HTTP: {
    CONTENT_TYPE: 'Content-Type',
    CONTENT_TYPE_VALUE: 'application/json'
  },
  AUTH_GUARD: {
    UNAUTHORIZED_REDIRECT: APP_CONSTANTS.ROUTING.PATH.UNAUTHORIZED_REDIRECT,
    UNAUTHORIZED_GUEST_REDIRECT: APP_CONSTANTS.ROUTING.PATH.UNAUTHORIZED_GUEST_REDIRECT
  },
  AUTH_REMOTE: {
    LOGIN_BODY_USERNAME_FIELD: 'username',
    LOGIN_BODY_PASSWORD_FIELD: 'password',
    HEADERS_CONTENT_TYPE_KEY: 'Content-Type',
    HEADERS_CONTENT_TYPE_VALUE: 'application/x-www-form-urlencoded',
    HEADERS_CHARSET_KEY: 'charset',
    HEADERS_CHARSET_VALUE: 'utf-8'
  },
  AUTH_SERVICE: {}
};

export const BASIC_AUTHENTICATION_MODULE_CONSTANTS: AuthenticationModuleConfig = {
  AUTH_HTTP: COMMON_AUTHENTICATION_MODULE_CONSTANTS.AUTH_HTTP,
  AUTH_GUARD: COMMON_AUTHENTICATION_MODULE_CONSTANTS.AUTH_GUARD,
  AUTH_REMOTE: Object.assign({},
    COMMON_AUTHENTICATION_MODULE_CONSTANTS.AUTH_REMOTE,
    {
      LOGIN_ENDPOINT: '/api/login',
      LOGOUT_ENDPOINT: '/api/logout',
      HEADERS_AUTHORIZATION_KEY: 'Authorization'
    }
  ),
  AUTH_SERVICE: {
    AUTHORIZATION_STORAGE_KEY: 'sessionId',
    PRINCIPAL_STORAGE_KEY: 'principal'
  }
};

export let AUTHENTICATION_MODULE_CONFIG = new InjectionToken<AuthenticationModuleConfig>('authentication.module.config');
