import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Season} from '@app/order-assortment/model/season';
import {Customer} from '@app/order-assortment/model/customer';
import {OrderAssortment} from '@app/order-assortment/model/order-assortment';
import {OrderAssortmentImp} from '@app/order-assortment/model/order-assortment.imp';
import {Currency} from '@app/order-assortment/model/currency/currency';
import {ShippingAddress} from '@app/order-assortment/model/shipping-address';

@Injectable({
  providedIn: 'root'
})

export class OrderAssortmentState {

  public currentOrderAssortment$: BehaviorSubject<OrderAssortment> = new BehaviorSubject<OrderAssortment>(this.currentOrderAssortment);
  public currentSeason$: BehaviorSubject<Season> = new BehaviorSubject<Season>(this.currentSeason);
  public currentCustomer$: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(this.currentCustomer);
  public currentCurrency$: BehaviorSubject<Currency> = new BehaviorSubject<Currency>(this.currentCurrency);
  public currentShippingAddresses$: BehaviorSubject<Array<ShippingAddress>> = new BehaviorSubject<Array<ShippingAddress>>(this.currentShippingAddresses);

  private _currentOrderAssortment: OrderAssortment;
  private _currentSeason: Season;
  private _currentCustomer: Customer;
  private _currentCurrency: Currency;
  private _currentShippingAddresses: Array<ShippingAddress>;

  constructor() {
  }

  get currentOrderAssortment(): OrderAssortment {
    return this._currentOrderAssortment;
  }

  set currentOrderAssortment(value: OrderAssortment) {
    this._currentOrderAssortment = value ? value : new OrderAssortmentImp();
    this.currentOrderAssortment$.next(this.currentOrderAssortment);
  }

  get currentSeason(): Season {
    return this._currentSeason;
  }

  set currentSeason(value: Season) {
    this._currentSeason = value;
    this.currentSeason$.next(this.currentSeason);
  }

  get currentCustomer(): Customer {
    return this._currentCustomer;
  }

  set currentCustomer(value: Customer) {
    this._currentCustomer = value;
    this.currentCustomer$.next(this.currentCustomer);
  }

  get currentCurrency(): Currency {
    return this._currentCurrency;
  }

  set currentCurrency(value: Currency) {
    this._currentCurrency = value;
    this.currentCurrency$.next(this.currentCurrency);
  }

  get currentShippingAddresses(): Array<ShippingAddress> {
    return this._currentShippingAddresses;
  }

  set currentShippingAddresses(value: Array<ShippingAddress>) {
    this._currentShippingAddresses = value;
    this.currentShippingAddresses$.next(this.currentShippingAddresses);
  }
}
