import {InjectionToken} from '@angular/core';
import {APP_CONSTANTS} from '@app/app.config';

export interface LanguageModuleConfig {
  REMOTES: any;
  CURRENT_TOKEN_KEY: string;
  DEFAULT_LANGUAGE: string;
}

export const LANGUAGE_MODULE_CONSTANTS: LanguageModuleConfig = {
  REMOTES: {
    URL: APP_CONSTANTS.BASE_URLS.OA + 'language'
  },
  CURRENT_TOKEN_KEY: 'language',
  DEFAULT_LANGUAGE: APP_CONSTANTS.DEFAULT_LANGUAGE
};

export let LANGUAGE_MODULE_CONFIG = new InjectionToken<LanguageModuleConfig>('language.module.config');
