import {JsonObject, JsonProperty} from 'json2typescript';
import {Customer} from '@app/order-assortment/model/customer/customer';
import {Comparable} from '@app/core/services/comparable';
import {isNil} from 'lodash';

@JsonObject('Customer')
export class CustomerImp extends Comparable<CustomerImp> implements Customer {

  @JsonProperty('id', String)
  private _id: string = undefined;

  @JsonProperty('name', String)
  private _name: string = undefined;

  @JsonProperty('code', String)
  private _code: string = undefined;

  constructor(id?: string, name?: string, code?: string) {
    super();
    this._id = id;
    this._name = name;
    this._code = code;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  equals(otherCustomer: Customer) {
    if (isNil(otherCustomer)) {
      return false;
    }
    return this.id === otherCustomer.id && this.name === otherCustomer.name && this.code === otherCustomer.name;
  }
}
