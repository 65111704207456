import {ModuleWithProviders, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {INTERCEPTOR_MODULE_CONFIG, INTERCEPTOR_MODULE_CONSTANTS} from '@app/core/interceptor/interceptor.module.config';
import {AuthInterceptor, OccInterceptor} from '@app/core/interceptor/interceptors/http.interceptor';
import {AuthenticationModule} from '@app/core/authentication/authentication.module';
import {HttpInterceptorHandlerService} from '@app/core/interceptor/handlers/http.interceptor-handler.service';


@NgModule({
  imports: [
    AuthenticationModule
  ],
  declarations: [],
  providers: []
})
export class InterceptorModule {
  public static forRoot(): ModuleWithProviders<InterceptorModule> {
    return {
      ngModule: InterceptorModule,
      providers: [
        {provide: INTERCEPTOR_MODULE_CONFIG, useValue: INTERCEPTOR_MODULE_CONSTANTS},
        {provide: HTTP_INTERCEPTORS, useClass: OccInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        HttpInterceptorHandlerService
      ]
    };
  }
}
