import {Injectable} from '@angular/core';
import {Dialog} from '@app/shared/dialogs/model/dialog.model';
import {Subject} from 'rxjs';

@Injectable()
export class DialogState {

  private _onCurrentDialogChange: Subject<Dialog> = new Subject<Dialog>();

  private _dialogStack: Array<Dialog> = [];

  constructor() { }

  get currentDialog(): Dialog {
    return this._dialogStack[this._dialogStack.length - 1];
  }

  set currentDialog(value: Dialog) {
    this._dialogStack.push(value);
    this._onCurrentDialogChange.next(value);
  }

  removeCurrentDialog() {
    this._dialogStack.pop();
    this._onCurrentDialogChange.next(this._dialogStack[this._dialogStack.length - 1]);
  }

  removeAllDialogs() {
    this._dialogStack = [];
    this._onCurrentDialogChange.next(undefined);
  }

  onCurrentDialogChanges() {
    return this._onCurrentDialogChange.asObservable();
  }

  getNumberOfDialogs() {
    return this._dialogStack.length;
  }
}
