<label>
  <select (change)="changeLanguage($event.target.value)">
    <option
      *ngFor="let language of languages | async"
      [value]="language.id"
      [selected]="language.id === currentLanguageId">
      {{language.label}}
    </option>
  </select>
</label>
