import {Angulartics2GoogleTagManager} from 'angulartics2';
import {Angulartics2} from 'angulartics2';
import {Inject, Injectable} from '@angular/core';
import {LanguageService} from '@app/shared/language/services/language.service';
import {ANALYTICS_MODULE_CONFIG, AnalyticsModuleConfig} from '@app/shared/analytics/analytics.config';
import {AnalyticsEvent} from '@app/shared/analytics/models/analytics-event';
import {OrderAssortmentService} from '@app/order-assortment/services/order-assortment.service';
import {Season} from '@app/order-assortment/model/season';
import {Customer} from '@app/order-assortment/model/customer';
import {UserService} from '@app/user/services';
import {User} from '@app/user/model';

declare var dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class Angulartics2DglGoogleTagManager extends Angulartics2GoogleTagManager {
  private lang: string;
  private customerId: string;
  private seasonId: string;
  private userType: string;

  constructor(angulartics2: Angulartics2,
              private languageService: LanguageService,
              private orderAssortmentService: OrderAssortmentService,
              private userService: UserService,
              @Inject(ANALYTICS_MODULE_CONFIG) private analyticsModuleConfig: AnalyticsModuleConfig) {
    super(angulartics2);
    this.registerLanguageChange();
    this.registerCustomerOrder();
    this.registerSeasonOrder();
    this.registerUserType();
  }

  pageTrack(path: string) {
    if (this.dataLayerExists()) {
      this.pushPageView(this.analyticsModuleConfig.gtm.events.PAGE_VIEW_EVENT, path);
    }
  }

  eventTrack(action: string, properties: AnalyticsEvent) {
    properties = properties || {};
    if (this.dataLayerExists()) {
      if (properties.event === this.analyticsModuleConfig.gtm.events.VIRTUAL_PAGE_VIEW_EVENT) {
        this.pushPageView(properties.event, properties.custom.path);
      } else {
        this.pushEvent(action, properties);
      }
    }
  }


  private dataLayerExists(): boolean {
    return typeof dataLayer !== 'undefined' && dataLayer;
  }

  private pushEvent(action: string, properties: AnalyticsEvent) {
    dataLayer.push({
      event: properties.event || this.analyticsModuleConfig.gtm.events.DEFAULT_EVENT,
      eventCategory: properties.category || this.analyticsModuleConfig.gtm.categories.DEFAULT_CATEGORY,
      eventAction: action || this.analyticsModuleConfig.gtm.actions.DEFAULT_ACTION,
      eventLabel: properties.label,
      ...properties.custom
    });
  }

  private pushPageView(event: string, path: string) {
    dataLayer.push({
      event: event,
      dsglUrl: path,
      customerId: this.customerId,
      season: this.seasonId,
      userType: this.userType,
      lang: this.lang
    });
  }

  private registerLanguageChange() {
    this.languageService.getCurrentLanguageId().subscribe((_lang) => {
      this.lang = _lang;
    });
  }

  private registerCustomerOrder() {
    this.orderAssortmentService.currentCustomerOrderAssortment().subscribe((customer: Customer) => {
      if (customer) {
        this.customerId = customer.code;
      }
    });
  }

  private registerSeasonOrder() {
    this.orderAssortmentService.currentSeasonOrderAssortment().subscribe((season: Season) => {
      if (season) {
        this.seasonId = season.name;
      }
    });
  }

  private registerUserType() {
    this.userService.currentUser().subscribe((user: User) => {
      if (user) {
        this.userType = (user.userType === this.analyticsModuleConfig.gtm.labels.CENTRAL || user.userType === this.analyticsModuleConfig.gtm.labels.CLIENT)
          ? this.analyticsModuleConfig.gtm.labels.CLIENTE : this.analyticsModuleConfig.gtm.labels.AGENT;
      }
    });
  }
}
