import {JsonObject, JsonProperty} from 'json2typescript';
import {OrderAssortment} from '@app/order-assortment/model/order-assortment';
import {Customer, CustomerImp} from '@app/order-assortment/model/customer';
import {Season, SeasonImp} from '@app/order-assortment/model/season';
import {CurrencyImp} from '@app/order-assortment/model/currency/currency.imp';
import {Currency} from '@app/order-assortment/model/currency/currency';
import {ProductColor, ProductColorImp} from '@app/order-assortment/model/product-color';
import {ShippingAddress} from '@app/order-assortment/model/shipping-address';
import {Comparable} from '@app/core/services/comparable';
import {differenceWith, isNil} from 'lodash';

@JsonObject('OrderAssortment')
export class OrderAssortmentImp extends Comparable<OrderAssortmentImp> implements OrderAssortment {

  @JsonProperty('customer', CustomerImp)
  private _customer: Customer = undefined;

  @JsonProperty('season', SeasonImp)
  private _season: Season = undefined;

  @JsonProperty('productColors', [ProductColorImp])
  private _productColors: Array<ProductColor> = [];

  @JsonProperty('currency', CurrencyImp)
  private _currency: Currency = undefined;

  private _shippingAddresses: Array<ShippingAddress> = [];

  constructor(customer?: Customer, season?: Season, productColors?: Array<ProductColor>, currency?: Currency, shippingAddresses?: Array<ShippingAddress>) {
    super();
    this._customer = customer || new CustomerImp();
    this._season = season || new SeasonImp();
    this._productColors = productColors || [];
    this._currency = currency || new CurrencyImp();
    this._shippingAddresses = shippingAddresses || [];
  }

  get customer(): Customer {
    return this._customer;
  }

  set customer(value: Customer) {
    this._customer = value;
  }

  get season(): Season {
    return this._season;
  }

  set season(value: Season) {
    this._season = value;
  }

  get productColors(): Array<ProductColor> {
    return this._productColors;
  }

  set productColors(value: Array<ProductColor>) {
    this._productColors = value;
  }

  get currency(): Currency {
    return this._currency;
  }

  set currency(value: Currency) {
    this._currency = value;
  }

  get shippingAddresses(): Array<ShippingAddress> {
    return this._shippingAddresses;
  }

  set shippingAddresses(value: Array<ShippingAddress>) {
    this._shippingAddresses = value;
  }

  equals(otherOrder: OrderAssortmentImp) {
    if (isNil(otherOrder)) {
      return false;
    }
    return this.customer.equals(otherOrder.customer) &&
      this.season.equals(otherOrder.season) &&
      this.currency.equals(otherOrder.currency) &&
      differenceWith(this.productColors, otherOrder.productColors, (productColor, otherProductColor) => productColor.equals(otherProductColor)).length === 0;
  }
}
