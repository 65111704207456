import {InjectionToken} from '@angular/core';
import {ModuleConfig} from '@app/app.config';

export interface InterceptorModuleConfig extends ModuleConfig {
  HTTP_STATUS: any;
  MESSAGE_KEYS: any;
}

export const INTERCEPTOR_MODULE_CONSTANTS: InterceptorModuleConfig = {
  HTTP_STATUS: {
    SUCCESS_STATUS_CODE: 200,
    BAD_REQUEST_ERROR_CODE: 400,
    AUTH_ERROR_STATUS_CODES: [401, 403],
    INTERNAL_SERVER_ERROR_STATUS_CODE: 500
  },
  MESSAGE_KEYS: {
    LOGIN_WRONG_CREDENTIALS_KEY: 'login.credentials.error',
    GLOBAL_ERROR_KEY: 'message.error.global'
  }
};

export let INTERCEPTOR_MODULE_CONFIG = new InjectionToken<InterceptorModuleConfig>('interceptor.module.config');
