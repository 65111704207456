import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {CompareOperator} from '@app/shared/form/model/compare-operator.enum';
import {findIndex, first, isDate, isNil, last} from 'lodash';

@Directive({
  selector: '[dglCompareValidator][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: CompareValidator, multi: true}]
})
export class CompareValidator implements Validator {

  @Input()
  private operator: CompareOperator;
  @Input()
  public dglCompareValidator: any;


  validate(control: AbstractControl): any {
    const modelToOperate = control.value;

    if (isNil(modelToOperate) || isNil(this.dglCompareValidator)) {
      return null;
    }
    return this.operate(modelToOperate, this.dglCompareValidator);
  }

  private operate(modelToCompare: any, inputToCompare: any) {
    modelToCompare = this.normalizeValue(modelToCompare);
    inputToCompare = this.normalizeValue(inputToCompare);

    switch (this.operator) {
      case CompareOperator.BIGGER:
        return modelToCompare > inputToCompare ? null : {biggerThan: {valid: false}};
      case CompareOperator.BIGGER_EQUALS:
        return modelToCompare >= inputToCompare ? null : {biggerThan: {valid: false}};
      case CompareOperator.SMALLER:
        return modelToCompare < inputToCompare ? null : {smallerThan: {valid: false}};
      case CompareOperator.SMALLER_EQUALS:
        return modelToCompare <= inputToCompare ? null : {smallerThan: {valid: false}};
      default:
        return modelToCompare === inputToCompare ? null : {equals: {valid: false}};
    }
  }

  private normalizeValue(value: any): number {
    if (isDate(value)) {
      return value.getTime();
    }
    return value;
  }
}
