import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CoreModule} from './core/core.module';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {APP_CONFIG, APP_CONSTANTS} from '@app/app.config';
import {SharedModule} from '@app/shared/shared.module';
import {LanguageService} from '@app/shared/language/services/language.service';
import {UserModule} from '@app/user/user.module';
import {ConfigurationService} from '@app/config/configuration.service';

export function initApp(languageService: LanguageService, configurationService: ConfigurationService) {
  return () => {
    configurationService.load();
    languageService.initLanguages();
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    UserModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [LanguageService, ConfigurationService]
    },
    {
      provide: APP_CONFIG,
      useValue: APP_CONSTANTS
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
