import {Injectable} from '@angular/core';
import {concat, differenceBy, find, forEach, intersectionBy} from 'lodash';
import {Observable} from 'rxjs/index';
import {OrderAssortmentRemote} from '@app/order-assortment/services/order-assortment.remote';
import {Season} from '@app/order-assortment/model/season';
import {Customer} from '@app/order-assortment/model/customer';
import {OrderAssortmentState} from '@app/order-assortment/model/order-assortment.state';
import {OrderAssortment} from '@app/order-assortment/model/order-assortment';
import {Currency} from '@app/order-assortment/model/currency/currency';
import {RefreshService} from '@app/refresh/services/refresh.service';
import {ShippingAddress} from '@app/order-assortment/model/shipping-address';
import {GlobalSpinnerService} from '@app/shared/spinner/services/globalspinner.service';

@Injectable({
  providedIn: 'root'
})
export class OrderAssortmentService {

  constructor(private orderAssortmentState: OrderAssortmentState,
              private orderAssortmentRemote: OrderAssortmentRemote,
              private refreshService: RefreshService,
              private globalSpinnerService: GlobalSpinnerService) {

    this.subscribeToRefresh();
  }

  currentOrderAssortment(): Observable<OrderAssortment> {
    return this.orderAssortmentState.currentOrderAssortment$.asObservable();
  }

  currentSeasonOrderAssortment(): Observable<Season> {
    return this.orderAssortmentState.currentSeason$.asObservable();
  }

  currentCustomerOrderAssortment(): Observable<Customer> {
    return this.orderAssortmentState.currentCustomer$.asObservable();
  }

  currentCurrencyOrderAssortment(): Observable<Currency> {
    return this.orderAssortmentState.currentCurrency$.asObservable();
  }

  currentShippingAddresses(): Observable<Array<ShippingAddress>> {
    return this.orderAssortmentState.currentShippingAddresses$.asObservable();
  }

  getCurrency(): Currency {
    return this.orderAssortmentState.currentCurrency;
  }

  loadOrderAssortment(): void {
    this.globalSpinnerService.showGlobalSpinner(this.orderAssortmentRemote.getOrderAssortment().subscribe((orderAssortment: OrderAssortment) => {
      this.orderAssortmentState.currentOrderAssortment = orderAssortment;
      this.orderAssortmentState.currentSeason = orderAssortment.season;
      this.orderAssortmentState.currentCustomer = orderAssortment.customer;
      this.orderAssortmentState.currentCurrency = orderAssortment.currency;
      this.getShipping();
    }));
  }

  private getShipping(): void {
    this.orderAssortmentRemote.getShipping().subscribe((shippingList) => {
      this.orderAssortmentState.currentOrderAssortment.shippingAddresses = shippingList;
      this.orderAssortmentState.currentShippingAddresses = shippingList;
    });
  }

  private subscribeToRefresh(): void {
    this.refreshService.onRefresh().subscribe(() => {
      this.loadOrderAssortment();
    });
  }

}
