import {InjectionToken} from '@angular/core';

export interface NotifierModuleConfig {
  TIMER_CONSTANTS: {
    INITIAL_DELAY: number;
    PERIOD: number;
  };
  MAXIMUM_ALERTS_SHOW: number;
}

export const NOTIFIER_MODULE_CONSTANTS: NotifierModuleConfig = {
  TIMER_CONSTANTS: {
    INITIAL_DELAY: 2000,
    PERIOD: 5000
  },
  MAXIMUM_ALERTS_SHOW: 3
};

export let NOTIFIER_MODULE_CONFIG = new InjectionToken<NotifierModuleConfig>('notifier.module.config');
