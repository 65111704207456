import {Currency} from '@app/order-assortment/model/currency/currency';
import {JsonObject, JsonProperty} from 'json2typescript';
import {Comparable} from '@app/core/services/comparable';
import {isNil} from 'lodash';

@JsonObject('Currency')
export class CurrencyImp extends Comparable<CurrencyImp> implements Currency {

  @JsonProperty('name', String)
  private _name: string;
  @JsonProperty('symbol', String)
  private _symbol: string;

  constructor(name?: string, symbol?: string) {
    super();
    this._name = name;
    this._symbol = symbol;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get symbol(): string {
    return this._symbol;
  }

  set symbol(value: string) {
    this._symbol = value;
  }

  equals(otherCurrency: Currency) {
    if (isNil(otherCurrency)) {
      return false;
    }
    return this.symbol === otherCurrency.symbol && this.name === otherCurrency.name;
  }
}
