import {Component, Inject} from '@angular/core';
import {DIALOG_MODULE_CONFIG} from '@app/shared/dialogs/dialog.module.config';

@Component({
  selector: 'dgl-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  constructor(@Inject(DIALOG_MODULE_CONFIG) public config) { }

}
