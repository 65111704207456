import {Injectable} from '@angular/core';
import {Meta} from '@angular/platform-browser';

export const OCC_BASE_URL_META_TAG_NAME = 'occ-backend-base-url';
export const OCC_BASE_URL_META_TAG_PLACEHOLDER = 'OCC_BACKEND_BASE_URL_VALUE';
export const FALLBACK_URL = 'https://localhost:9002';

@Injectable({
  providedIn: 'root',
})
export abstract class OccConfig {
  backend?: {
    occ?: {
      baseUrl?: string;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private _configuration: OccConfig = {};

  constructor(private meta: Meta) {}

  load(): void {
    const baseUrl = getMetaTagContent(OCC_BASE_URL_META_TAG_NAME, this.meta);
    if (baseUrl && baseUrl !== OCC_BASE_URL_META_TAG_PLACEHOLDER) {
      this._configuration = {backend: {occ: {baseUrl}}};
    } else {
      this._configuration = {backend: {occ: {baseUrl: FALLBACK_URL}}};
    }
  }

  getConfig(): OccConfig {
    return this._configuration;
  }
}

function getMetaTagContent(name: string, meta: Meta) {
  const metaTag = meta.getTag(`name="${name}"`);
  return metaTag && metaTag.content;
}
