<div *ngIf="inputForm?.errors && (checkPristine || (inputForm?.dirty && inputForm?.touched))">
  <p-message *ngIf="inputForm.errors.required" severity="error"
             text="{{'form.error.required' | translate}}"></p-message>
  <p-message *ngIf="inputForm.errors.pattern" severity="error" text="{{'form.error.pattern' | translate}}"></p-message>
  <p-message *ngIf="inputForm.errors.email" severity="error" text="{{'form.error.mail' | translate}}"></p-message>
  <p-message *ngIf="inputForm.errors.rangeSelected" severity="error"
             text="{{'form.error.rangeSelected' | translate}}"></p-message>
  <p-message *ngIf="inputForm.errors.min" severity="error" text="{{'form.error.min' | translate}}"></p-message>
  <p-message *ngIf="inputForm.errors.max" severity="error" text="{{'form.error.max' | translate}}"></p-message>
  <p-message *ngIf="inputForm.errors.maxlength" severity="error"
             text="{{'form.error.maxlength' | translate}}"></p-message>
  <p-message *ngIf="inputForm.errors.minlength" severity="error"
             text="{{'form.error.minlength' | translate}}"></p-message>
  <p-message *ngIf="inputForm.errors.biggerThan" severity="error"
             text="{{'form.error.biggerThan' | translate}}"></p-message>
  <p-message *ngIf="inputForm.errors.smallerThan" severity="error"
             text="{{'form.error.smallerThan' | translate}}"></p-message>
  <p-message *ngIf="inputForm.errors.equals" severity="error"
             text="{{'form.error.equalsThan' | translate}}"></p-message>
</div>
