import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BasicAuthenticationService} from '@app/core/authentication/basic/auth/basic-authentication.service';
import {AuthHttp} from '@app/core/authentication';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class BasicAuthHttp extends AuthHttp {

  private readonly AUTHORIZATION_COOKIE_NAME = 'JSESSIONID';
  private readonly CONTENT_TYPE_HEADER = 'Content-type';
  private readonly ACCEPT_HEADER = 'Accept';
  private readonly AUTHORIZATION_HEADER = 'Authorization';
  private readonly CONTENT_TYPE_DEFAULT_VALUE = 'application/json';
  private readonly ACCEPT_DEFAULT_VALUE = 'application/json';

  constructor(private http: HttpClient, private authenticationService: BasicAuthenticationService, private cookieService: CookieService) {
    super();
  }

  get<T>(url: string, params?: HttpParams, headers?: HttpHeaders, responseType?: any): Observable<T> {
    return this.http.get<T>(url, {headers: this.createHeaders(headers), params: params, responseType: responseType});
  }

  post<T>(url: string, body: any | any, params?: HttpParams, headers?: HttpHeaders): Observable<T> {
    return this.http.post<T>(url, body, {headers: this.createHeaders(headers), params: params});
  }

  put<T>(url: string, body: any | any, headers?: HttpHeaders): Observable<T> {
    return this.http.put<T>(url, body, {headers: this.createHeaders(headers)});
  }

  delete<T>(url: string, headers?: HttpHeaders): Observable<T> {
    return this.http.delete<T>(url, {headers: this.createHeaders(headers)});
  }

  patch<T>(url: string, body: any | any, headers?: HttpHeaders): Observable<T> {
    return this.http.patch<T>(url, body, {headers: this.createHeaders(headers)});
  }

  private createHeaders(_headers?: HttpHeaders): HttpHeaders {
    this.setAuthorizationCookie();
    const contentType = _headers ? (_headers.get(this.CONTENT_TYPE_HEADER) || this.CONTENT_TYPE_DEFAULT_VALUE) : this.CONTENT_TYPE_DEFAULT_VALUE;
    const accept = _headers ? (_headers.get(this.ACCEPT_HEADER) || this.ACCEPT_DEFAULT_VALUE) : this.ACCEPT_DEFAULT_VALUE;
    const headers = _headers || new HttpHeaders();
    return headers
      .set(this.CONTENT_TYPE_HEADER, contentType)
      .set(this.ACCEPT_HEADER, accept)
      .set(this.AUTHORIZATION_HEADER, this.authenticationService.authorization);
  }

  private setAuthorizationCookie() {
    this.cookieService.set(this.AUTHORIZATION_COOKIE_NAME, this.authenticationService.authorization);
  }
}
