import {JsonConvert, OperationMode, ValueCheckingMode} from 'json2typescript';

export class Serializer {

  protected jsonConvert: JsonConvert;

  constructor() {
    this.jsonConvert = new JsonConvert();
    this.jsonConvert.operationMode = OperationMode.ENABLE;
    this.jsonConvert.ignorePrimitiveChecks = false;
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  }

  protected serialize<T extends object>(clazz: T): JSON {
    return this.jsonConvert.serialize(clazz);
  }

  protected deserialize<T extends object>(json: JSON, type: any): T {
   return this.jsonConvert.deserializeObject<T>(json, type);
  }

  protected deserializeArray<T extends object>(json: JSON[], type: any): T[] {
    return this.jsonConvert.deserializeArray<T>(json, type);
  }
}
