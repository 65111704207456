import {Injectable} from '@angular/core';
import {LocalStorage} from 'ngx-webstorage';
import {User, UserImp, UserState} from '@app/user/model';
import {USER_MODULE_CONSTANTS} from '@app/user/user.module.config';
import {Observable} from 'rxjs';


@Injectable()
export class UserService {
  @LocalStorage(USER_MODULE_CONSTANTS.USER_STORAGE_KEY)
  user;

  constructor(private userState: UserState) {
    this.loadCurrentUser();
  }

  currentUser(): Observable<User> {
    return this.userState.currentUser$.asObservable();
  }

  private loadCurrentUser() {
    if (this.user) {
      this.userState.currentUser = new UserImp(this.user.login, this.user.name, this.user.roles, this.user.userType);
    }
  }
}
