import {JsonObject, JsonProperty} from 'json2typescript';
import {Territory} from '@app/order-assortment/model/territory/territory';
import {Comparable} from '@app/core/services/comparable';
import {isNil} from 'lodash';

@JsonObject('Territory')
export class TerritoryImp extends Comparable<Territory> implements Territory {

  @JsonProperty('id', String)
  private _id: string = undefined;

  @JsonProperty('name', String)
  private _name: string = undefined;

  constructor(id?: string, name?: string) {
    super();
    this._id = id;
    this._name = name;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  equals(other: TerritoryImp) {
    if (isNil(other)) {
      return false;
    }
    return this.id === other.id &&
      this.name === other.name;
  }
}
