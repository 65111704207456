import { ModuleConfig } from '@app/app.config';
import { InjectionToken } from '@angular/core';

export interface UserModuleConfig extends ModuleConfig {
  USER_STORAGE_KEY: string;
}

export const USER_MODULE_CONSTANTS: UserModuleConfig = {
  USER_STORAGE_KEY: 'user'
};

export let USER_MODULE_CONFIG = new InjectionToken<UserModuleConfig>('user.module.config');
