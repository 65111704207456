import {InjectionToken} from '@angular/core';

export class SpinnerModuleConfig {
  BUTTON_CSS: string;
  GLOBAL_CSS: string;
  DELAY: number;
}

export const SPINNER_MODULE_CONSTANTS: SpinnerModuleConfig = {
  BUTTON_CSS: 'dglSpinner-left',
  GLOBAL_CSS: 'dglSpinner',
  DELAY: 1000
};

export let SPINNER_MODULE_CONFIG = new InjectionToken<SpinnerModuleConfig>('spinner.module.config');

