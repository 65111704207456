import {NotificationType} from '@app/shared/notifier/model/notification.enum';
import {Notification} from '@app/shared/notifier/model/notification';

export class NotificationImp implements Notification {

  private _message: string;
  private _type: NotificationType;

  constructor(message: string, type: NotificationType) {
    this._message = message;
    this._type = type;
  }

  get type(): NotificationType {
    return this._type;
  }

  set type(value: NotificationType) {
    this._type = value;
  }

  get message(): string {
    return this._message;
  }

  set message(value: string) {
    this._message = value;
  }

}
