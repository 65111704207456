import {User} from '@app/user/model/user';
export class UserImp implements User {

  private _login: string = undefined;

  private _name: string = undefined;

  private _roles: string[] = undefined;

  private _userType: string = undefined;

  constructor(login?: string, name?: string, roles?: string[], userType?: string) {
    this._login = login;
    this._roles = roles;
    this._userType = userType;
    this._name = name;
  }

  get login(): string {
    return this._login;
  }

  set login(value: string) {
    this._login = value;
  }


  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get roles(): string[] {
    return this._roles;
  }

  set roles(value: string[]) {
    this._roles = value;
  }

  get userType(): string {
    return this._userType;
  }

  set userType(value: string) {
    this._userType = value;
  }
}
