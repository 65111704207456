import {Inject, Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '@app/core/authentication/auth/authentication.service';
import {
  AUTHENTICATION_MODULE_CONFIG,
  AuthenticationModuleConfig
} from '@app/core/authentication/authentication.module.config';
import * as _ from 'lodash';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private router: Router, private authenticationService: AuthenticationService,
              @Inject(AUTHENTICATION_MODULE_CONFIG) private authenticationModuleConfig: AuthenticationModuleConfig) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const requiredRoles = _.get(route, 'data.roles');
    if (requiredRoles) {
      if (this.authenticationService.isLoggedIn()
        && this.userHasAnyRequiredRole(this.authenticationService.getPrincipal().roles, requiredRoles)) {
        return true;
      }
    } else if (this.authenticationService.isLoggedIn()) {
      return true;
    }

    this.router.navigate([this.authenticationModuleConfig.AUTH_GUARD.UNAUTHORIZED_REDIRECT]);
    return false;
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const requiredRoles = _.get(route, 'data.roles');
    if (requiredRoles) {
      if (!(this.authenticationService.isLoggedIn()
          && this.userHasAnyRequiredRole(this.authenticationService.getPrincipal().roles, requiredRoles))) {
        this.router.navigate([this.authenticationModuleConfig.AUTH_GUARD.UNAUTHORIZED_REDIRECT]);
      }
    } else if (!this.authenticationService.isLoggedIn()) {
      this.router.navigate([this.authenticationModuleConfig.AUTH_GUARD.UNAUTHORIZED_REDIRECT]);
    }
    return this.authenticationService.isLoggedIn();
  }

  private userHasAnyRequiredRole(userRoles: String[], requiredRoles: String[]) {
    return _.intersection(userRoles, requiredRoles).length > 0;
  }
}
