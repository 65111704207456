import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {map} from 'rxjs';
import {Serializer} from '@app/core/services/serializer';
import {AuthHttp} from '@app/core/authentication';
import {ORDER_ASSORTMENT_MODULE_CONFIG, OrderAssortmentModuleConfig} from '@app/order-assortment/order-assortment.module.config';
import {OrderAssortmentImp} from '@app/order-assortment/model/order-assortment.imp';
import {OrderAssortment} from '@app/order-assortment/model/order-assortment';

@Injectable({
  providedIn: 'root'
})
export class OrderAssortmentRemote extends Serializer {

  constructor(@Inject(ORDER_ASSORTMENT_MODULE_CONFIG) private orderModuleConfig: OrderAssortmentModuleConfig,
              private authHttp: AuthHttp) {
    super();
  }

  getOrderAssortment(): Observable<OrderAssortment> {
    return this.authHttp.get(this.orderModuleConfig.REMOTES.ORDERS.URL).pipe(map((data: any) => {
      return this.deserialize(data, OrderAssortmentImp);
    }));
  }

  getShipping(): Observable<any> {
    return this.authHttp.get(this.orderModuleConfig.REMOTES.SHIPPING.URL).pipe(map((data: any) => {
      return data;
    }));
  }
}
