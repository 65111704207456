import {NgModule} from '@angular/core';
import {SharedModule} from 'primeng/api';
import {UserState} from '@app/user/model';
import {UserService} from '@app/user/services';
import {USER_MODULE_CONFIG, USER_MODULE_CONSTANTS} from '@app/user/user.module.config';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [],
  providers: [
    {provide: USER_MODULE_CONFIG, useValue: USER_MODULE_CONSTANTS},
    UserState,
    UserService
  ]
})
export class UserModule {
}
