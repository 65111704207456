import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap, catchError} from 'rxjs/operators';
import {HttpInterceptorHandlerService} from '../handlers/http.interceptor-handler.service';
import {ConfigurationService} from '@app/config/configuration.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const httpInterceptorHandlerService: HttpInterceptorHandlerService = this.injector.get(HttpInterceptorHandlerService);
    return next.handle(req).pipe(
      tap(evt => {
      httpInterceptorHandlerService.manageSuccess(evt);
    }),
      catchError((error: HttpErrorResponse) => {
      return httpInterceptorHandlerService.manageErrors(error);
    }));
  }

}

@Injectable()
export class OccInterceptor implements HttpInterceptor {

  constructor(private configurationService: ConfigurationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newUrl = this.configurationService.getConfig().backend.occ.baseUrl.concat(req.url);
    return next.handle(req.clone({url: newUrl}));
  }

}
