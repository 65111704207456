import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MessageModule} from 'primeng/message';
import {FormMessagesComponent} from '@app/shared/form/components/messages/form-messages.component';
import {CompareValidator} from '@app/shared/form/validators/compare-validator.directive';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MessageModule
  ],
  declarations: [FormMessagesComponent, CompareValidator],
  exports: [FormMessagesComponent]
})
export class FormUtilsModule {
}
