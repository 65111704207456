import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Confirmation, ConfirmationService} from 'primeng/api';
import {DIALOG_MODULE_CONFIG} from '@app/shared/dialogs/dialog.module.config';
import {Dialog} from '@app/shared/dialogs/model/dialog.model';
import {DialogState} from '@app/shared/dialogs/model/dialog.state';

@Injectable()
export class DialogService {

  private _confirmation: Confirmation;
  private _dialog: Dialog;

  constructor(@Inject(DIALOG_MODULE_CONFIG) private config, private confirmationService: ConfirmationService,
              private translateService: TranslateService, private dialogState: DialogState) {
  }

  numberOfDialogs() {
    return this.dialogState.getNumberOfDialogs();
  }

  confirm(confirmation?: Confirmation) {

    const setConfirmationDefaults = map((conf: Confirmation) => {
      this._confirmation = conf || ({message: undefined, header: undefined} as Confirmation);
      this._confirmation.acceptLabel = this._confirmation.acceptLabel || this.config.CONFIRMATION.DEFAULT.ACCEPT_KEY;
      this._confirmation.rejectLabel = this._confirmation.rejectLabel || this.config.CONFIRMATION.DEFAULT.REJECT_KEY;
      this._confirmation.message = this._confirmation.message || this.config.CONFIRMATION.DEFAULT.MESSAGE_KEY;
      return confirmation;
    });

    const getTranslations = map((conf: Confirmation) => {
      const keys = [this._confirmation.message, this._confirmation.acceptLabel, this._confirmation.rejectLabel, ...(this._confirmation.header ? [this._confirmation.header] : [])];
      return this.translateService.get(keys);
    });

    const setTranslations = map((translations: any) => {
      if (this._confirmation.header) {
        this._confirmation.header = translations.value[this._confirmation.header];
      }
      this._confirmation.message = translations.value[this._confirmation.message];
      this._confirmation.acceptLabel = translations.value[this._confirmation.acceptLabel];
      this._confirmation.rejectLabel = translations.value[this._confirmation.rejectLabel];
      return confirmation;
    });

    of(confirmation)
      .pipe(
        setConfirmationDefaults,
        getTranslations,
        setTranslations
      )
      .subscribe(() => {
        this.confirmationService.confirm(this._confirmation);
      });
  }

  dialog(dialog: Dialog) {

    const setDialogDefaults = map((d: Dialog) => {
      this._dialog = d;
      return this._dialog;
    });

    const getTranslations = map((d: Dialog) => {
      if (d.header) {
        return this.translateService.get(d.header);
      } else {
        return undefined;
      }
    });

    const setTranslations = map((translations: any) => {
      this._dialog.header = translations ? translations.value : undefined;
    });

    of(dialog)
      .pipe(
        setDialogDefaults,
        getTranslations,
        setTranslations,
      )
      .subscribe(() => {
        this.dialogState.currentDialog = this._dialog;
      });
  }

  closeDialog() {
    this.dialogState.removeCurrentDialog();
  }

}
