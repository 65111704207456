import {Inject, Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route} from '@angular/router';
import {Observable} from 'rxjs';

import {AuthenticationService} from '@app/core/authentication/auth/authentication.service';
import {
  AUTHENTICATION_MODULE_CONFIG,
  AuthenticationModuleConfig
} from '@app/core/authentication/authentication.module.config';

@Injectable()
export class AuthGuestGuard implements CanActivate, CanLoad {


  constructor(private router: Router, private authenticationService: AuthenticationService,
              @Inject(AUTHENTICATION_MODULE_CONFIG) private authenticationModuleConfig: AuthenticationModuleConfig) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authenticationService.isLoggedIn()) {
      return true;
    }
    this.router.navigate([this.authenticationModuleConfig.AUTH_GUARD.UNAUTHORIZED_GUEST_REDIRECT]);
    return false;
  }

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.authenticationService.isLoggedIn()) {
      return true;
    }
    this.router.navigate([this.authenticationModuleConfig.AUTH_GUARD.UNAUTHORIZED_GUEST_REDIRECT]);
    return false;
  }
}



