import {Component, OnDestroy, OnInit} from '@angular/core';
import {LanguageService} from '@app/shared/language/services/language.service';
import {Language} from '@app/shared/language/model/language';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'dgl-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {

  languages: Observable<Language[]>;
  currentLanguageId: string;
  private _subscriptions: Subscription = new Subscription();

  constructor(private languageService: LanguageService) {
  }

  ngOnInit() {
    this.languages = this.languageService.getAvailableLanguages();
    this._subscriptions.add(this.languageService.getCurrentLanguageId().subscribe((languageId) => {
      this.currentLanguageId = languageId;
    }));
  }

  public changeLanguage(languageId: string) {
    this.languageService.change(languageId);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
