import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Language} from '@app/shared/language/model/language';

@Injectable()
export class LanguageState {

  private _availableLanguages: Language[] = [];
  private _currentLanguageId: string;

  public currentLanguageId$: BehaviorSubject<string> = new BehaviorSubject<string>(this.currentLanguageId);
  public availableLanguages$: Subject<Language[]> = new Subject<Language[]>();

  constructor() {
  }

  set availableLanguages(value: Language[]) {
    this._availableLanguages = value;
    this.availableLanguages$.next(this._availableLanguages);
  }

  get currentLanguageId (): string {
    return this._currentLanguageId;
  }

  set currentLanguageId(value: string) {
    this._currentLanguageId = value;
    this.currentLanguageId$.next(this._currentLanguageId);
  }

  isValidLanguage(languageId: string) {
    return this._availableLanguages.find((l: Language) => l.id === languageId) !== undefined;
  }
}
