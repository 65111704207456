import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DIALOG_MODULE_CONFIG, DIALOG_MODULE_CONSTANTS} from '@app/shared/dialogs/dialog.module.config';
import {DialogModule as PrimeNgDialogModule} from 'primeng/dialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {TranslateModule} from '@ngx-translate/core';
import {ConfirmationDialogComponent, DialogComponent} from '@app/shared/dialogs/components';
import {DialogService} from '@app/shared/dialogs/services';
import {DialogState} from '@app/shared/dialogs/model';
import {DialogAnalyticsService} from '@app/shared/dialogs/services/analytics/dialog.analytics.service';


@NgModule({
  imports: [
    CommonModule,
    PrimeNgDialogModule,
    ConfirmDialogModule,
    TranslateModule
  ],
  declarations: [DialogComponent, ConfirmationDialogComponent],
  exports: [DialogComponent, ConfirmationDialogComponent]
})
export class DialogModule {
  public static forRoot(): ModuleWithProviders<DialogModule> {
    return {
      ngModule: DialogModule,
      providers: [
        {provide: DIALOG_MODULE_CONFIG, useValue: DIALOG_MODULE_CONSTANTS},
        DialogService,
        DialogState,
        ConfirmationService,
        DialogAnalyticsService
      ]
    };
  }
}
