import {APP_CONSTANTS, ModuleConfig} from '@app/app.config';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {InjectionToken} from '@angular/core';

export interface CoreModuleConfig extends ModuleConfig {
  WEBSTORAGE_CONFIG: NgxWebstorageModule;
  TRANSLATE_CONFIG: {
    I18N_PATH: string,
    I18N_PATH_WIDGET_MY_ANALYSIS: string,
    SUFFIX_FILE: string
  };
}

export const CORE_MODULE_CONSTANTS: CoreModuleConfig = {
  WEBSTORAGE_CONFIG: {
    prefix: '',
    separator: '',
    caseSensitive: true
  },
  TRANSLATE_CONFIG: {
    I18N_PATH: APP_CONSTANTS.BASE_URLS.OA + 'translations/oa/',
    I18N_PATH_WIDGET_MY_ANALYSIS: APP_CONSTANTS.BASE_URLS.OA + 'translations/oa/',
    // I18N_PATH_WIDGET_MY_ANALYSIS: APP_CONSTANTS.BASE_URLS.DESIGUAL_API + 'translation/widgetmyanalysis/',
    SUFFIX_FILE: ''
  }
};

export let CORE_MODULE_CONFIG = new InjectionToken<CoreModuleConfig>('core.module.config');
