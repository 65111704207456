import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {timer} from 'rxjs';
import {NOTIFIER_MODULE_CONFIG, NotifierModuleConfig} from '@app/shared/notifier/notifier.module.config';
import {NotificationType} from '@app/shared/notifier/model/notification.enum';
import {Notification} from '@app/shared/notifier/model/notification';

@Component({
  selector: 'dgl-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  type = NotificationType;

  @Input() notification: Notification;
  @Output() closeMessage = new EventEmitter<Notification>();

  constructor(@Inject(NOTIFIER_MODULE_CONFIG) private notifierModuleConfig: NotifierModuleConfig) {
  }

  ngOnInit(): void {
    this.closeAutomatically();
  }

  private closeAutomatically() {
    const timer$ = timer(this.notifierModuleConfig.TIMER_CONSTANTS.INITIAL_DELAY, this.notifierModuleConfig.TIMER_CONSTANTS.PERIOD);
    const subscription = timer$.subscribe(() => {
      this.closeMessage.emit(this.notification);
      subscription.unsubscribe();
    });
  }

}
