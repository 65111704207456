<p-dialog
  [(visible)]="display"
  [draggable]="dialogConfig.draggable"
  [resizable]="dialogConfig.resizable"
  [contentStyle]="dialogConfig.contentStyle"
  [modal]="dialogConfig.modal"
  [closeOnEscape]="dialogConfig.closeOnEscape"
  [dismissableMask]="dialogConfig.dismissableMask"
  [closable]="dialogConfig.closable"
  [showHeader]="dialogConfig.showHeader"
  header="{{ dialogHeader }}"
  (onShow)="onShow()"
  (onHide)="onHide()">
  <ng-container #dialogBody></ng-container>

</p-dialog>

