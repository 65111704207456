import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Angulartics2DglGoogleTagManager} from './services/angulartics2-dgl-gtm';
import {Angulartics2Module} from 'angulartics2';
import {ANALYTICS_MODULE_CONFIG, ANALYTICS_MODULE_DI_CONFIG} from '@app/shared/analytics/analytics.config';
import {UserModule} from '@app/user/user.module';
import { Angulartics2Settings } from 'angulartics2';

@NgModule({
  imports: [
    CommonModule,
    UserModule,
    Angulartics2Module.forRoot(ANALYTICS_MODULE_DI_CONFIG.angulartics.settings),
  ],
  exports: [
    Angulartics2Module
  ]
})
export class AnalyticsModule {
  public static forRoot(): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [
        {provide: ANALYTICS_MODULE_CONFIG, useValue: ANALYTICS_MODULE_DI_CONFIG}
      ]
    };
  }
}
