import {BasicPrincipal} from '@app/core/authentication/model/basic-principal';

export class BasicPrincipalImp implements BasicPrincipal {

  private _sessionId: string = undefined;
  private _username: string = undefined;
  private _roles: String[] = undefined;

  constructor(sessionId: string, username: string, roles: String[]) {
    this._sessionId = sessionId;
    this._username = username;
    this._roles = roles;

    Object.freeze(this);
  }

  get sessionId(): string {
    return this._sessionId;
  }

  get username(): string {
    return this._username;
  }

  get roles(): String[] {
    return this._roles;
  }
}
