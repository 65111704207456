import {Inject, Injectable} from '@angular/core';
import {Serializer} from '@app/core/services/serializer';
import {AuthHttp} from '@app/core/authentication';
import {LANGUAGE_MODULE_CONFIG, LanguageModuleConfig} from '@app/shared/language/language.module.config';
import {Observable} from 'rxjs/index';
import {Language} from '@app/shared/language/model/language';
import {LanguageImp} from '@app/shared/language/model/language-imp';
import {map} from 'rxjs';

@Injectable()
export class LanguageRemote extends Serializer {

  constructor(private authHttp: AuthHttp,
              @Inject(LANGUAGE_MODULE_CONFIG) private languageModuleConfig: LanguageModuleConfig) {
    super();
  }

  public getLanguages(): Observable<Language[]> {
    return this.authHttp.get(this.languageModuleConfig.REMOTES.URL).pipe(
      map((data: any) => {
        return this.deserializeArray(data.languages, Array<LanguageImp>);
      }));
  }

}
