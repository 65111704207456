import {JsonObject, JsonProperty} from 'json2typescript';
import {Line} from '@app/order-assortment/model/line/line';
import {Comparable} from '@app/core/services/comparable';
import {Family} from '@app/order-assortment/model/family/family';
import {isNil} from 'lodash';

@JsonObject('Line')
export class LineImp extends Comparable<Line> implements Line {

  @JsonProperty('id', String)
  private _id: string = undefined;

  @JsonProperty('name', String)
  private _name: string = undefined;

  constructor(id?: string, name?: string) {
    super();
    this._id = id;
    this._name = name;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  equals(other: Family) {
    if (isNil(other)) {
      return false;
    }
    return this.id === other.id &&
      this.name === other.name;
  }
}
