import {ModuleWithProviders, NgModule} from '@angular/core';
import {LanguageSelectorComponent} from './components/language-selector.component';
import {LANGUAGE_MODULE_CONFIG, LANGUAGE_MODULE_CONSTANTS} from '@app/shared/language/language.module.config';
import {LanguageService} from '@app/shared/language/services/language.service';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {LanguageRemote} from '@app/shared/language/services/language.remote';
import {LanguageState} from '@app/shared/language/model/language.state';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [LanguageSelectorComponent],
  exports: [LanguageSelectorComponent]
})
export class LanguageModule {

  public static forRoot(): ModuleWithProviders<LanguageModule> {
    return {
      ngModule: LanguageModule,
      providers: [
        {provide: LANGUAGE_MODULE_CONFIG, useValue: LANGUAGE_MODULE_CONSTANTS},
        LanguageService,
        LanguageRemote,
        LanguageState
      ]
    };
  }
}
