import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NotifierModule} from '@app/shared/notifier/notifier.module';
import {LanguageModule} from '@app/shared/language/language.module';
import {SpinnerModule} from '@app/shared/spinner/spinner.module';
import {DialogModule} from '@app/shared/dialogs/dialog.module';
import {FormUtilsModule} from '@app/shared/form/form-utils.module';
import {AnalyticsModule} from '@app/shared/analytics/analytics.module';
import {ChartModule} from 'primeng/chart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ProgressBarModule} from 'primeng/progressbar';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {TableModule} from 'primeng/table';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NotifierModule,
    LanguageModule,
    SpinnerModule,
    DialogModule,
    FormUtilsModule,
    OverlayPanelModule,
    SidebarModule,
    ToggleButtonModule,
    SelectButtonModule,
    ProgressBarModule,
    ChartModule,
    AnalyticsModule,
    TableModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NotifierModule,
    LanguageModule,
    SpinnerModule,
    DialogModule,
    FormUtilsModule,
    OverlayPanelModule,
    SidebarModule,
    ToggleButtonModule,
    SelectButtonModule,
    ProgressBarModule,
    ProgressBarModule,
    ChartModule,
    AnalyticsModule,
    TableModule
  ]
})
export class SharedModule {
}
