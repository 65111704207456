import {InjectionToken} from '@angular/core';
import {APP_CONSTANTS} from '@app/app.config';

export interface OrderAssortmentModuleConfig {
  REMOTES: any;
}

export const ORDER_ASSORTMENT_MODULE_CONSTANTS: OrderAssortmentModuleConfig = {
  REMOTES: {
    ORDERS: {
      URL: APP_CONSTANTS.BASE_URLS.OA + 'orders'
    },
    SHIPPING: {
      URL: APP_CONSTANTS.BASE_URLS.OA + 'shipping'
    }
  }
};

export let ORDER_ASSORTMENT_MODULE_CONFIG = new InjectionToken<OrderAssortmentModuleConfig>('order-assortment.module.config', {
  providedIn: 'root',
  factory: () => ORDER_ASSORTMENT_MODULE_CONSTANTS
});
