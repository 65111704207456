import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

import {NotificationImp} from '@app/shared/notifier/model/notification-imp';
import {NotificationType} from '@app/shared/notifier/model/notification.enum';
import {Notification} from '@app/shared/notifier/model/notification';


@Injectable()
export class NotifierService {
  private subjectNotification = new BehaviorSubject<Notification>(null);
  private subjectReset = new Subject<Notification>();

  constructor() {
  }

  notifyError(message: string) {
    const notification = new NotificationImp(message, NotificationType.ERROR);
    this.subjectNotification.next(notification);
  }

  notifySuccess(message: string) {
    const notification = new NotificationImp(message, NotificationType.SUCCESS);
    this.subjectNotification.next(notification);
  }

  notifyWarning(message: string) {
    const notification = new NotificationImp(message, NotificationType.WARNING);
    this.subjectNotification.next(notification);
  }

  notifyReset() {
    this.subjectReset.next(null);
  }

  onReset(callback: (notification: Notification) => void) {
    this.subjectReset.asObservable().subscribe(callback);
  }

  onNotify(callback: (notification: Notification) => void) {
    this.subjectNotification.asObservable().subscribe(callback);
  }

}
