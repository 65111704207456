import {AfterContentInit, Directive, ElementRef, Inject, Input, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription, Observable, Subject} from 'rxjs';
import {finalize} from 'rxjs/operators';

import {SPINNER_MODULE_CONFIG} from '@app/shared/spinner/spinner.config';

@Directive({
  selector: '[dglSpinner]'
})
export class DglSpinnerDirective implements OnDestroy, AfterContentInit {
  el: HTMLElement;
  showSpinner: Boolean = false;
  private spinnerSubscription: Subscription;

  constructor(el: ElementRef,
              @Inject(SPINNER_MODULE_CONFIG) private spinnerConfig) {
    this.el = el.nativeElement;
  }

  @Input()
  set dglSpinner(passedValue: any) {
    const isBoolean: boolean = typeof passedValue === 'boolean';
    const isObservable: boolean = passedValue instanceof Observable;
    const isSubscription: boolean = passedValue instanceof Subscription;
    const isSpinnerSubject: boolean = passedValue instanceof (BehaviorSubject || Subject);
    if (isBoolean) {
      this.showSpinner = passedValue;
      this.checkAndInitHandler(this.el);
    } else {
      this.showSpinner = isObservable || isSubscription || isSpinnerSubject;
      this.checkAndInitHandler(this.el);
      if (isObservable || isSpinnerSubject) {
        this.observableListener(passedValue);
      }
      if (isSubscription) {
        this.subscriptionListener(passedValue);
      }
    }
  }

  private subscriptionListener(passedValue: Subscription) {
    passedValue.add(() => {
      this.removeSpinnerClass(this.el);
    });
  }

  private observableListener(passedValue: Observable<any> | BehaviorSubject<any> | Subject<any>) {
    this.spinnerSubscription = passedValue.pipe(
      finalize(
      () => this.removeSpinnerClass(this.el)
    )).subscribe(
      () => {
        this.spinnerSubscription.unsubscribe();
      }, () => {
        this.spinnerSubscription.unsubscribe();
      });
  }

  ngAfterContentInit(): void {
    this.checkAndInitHandler(this.el);
  }

  ngOnDestroy(): void {
    if (this.spinnerConfig.DELAY) {
      clearTimeout(this.spinnerConfig.DELAY);
    }
    if (this.spinnerSubscription) {
      this.spinnerSubscription.unsubscribe();
    }
  }

  private checkAndInitHandler(el: HTMLElement) {
    if (this.showSpinner) {
      this.loadSpinnerInElement(el);
    } else if (!this.showSpinner) {
      this.removeSpinnerClass(el);
    }
  }

  private loadSpinnerInElement(el: HTMLElement) {
    if (el instanceof HTMLButtonElement) {
      this.btnDisable(el);
      this.addSpinnerBtnClass(el);
    } else {
      this.addSpinnerGlobalClass(el);
    }
    this.showSpinner = true;
  }

  private addSpinnerGlobalClass(el: HTMLElement) {
    el.classList.add(this.spinnerConfig.GLOBAL_CSS);
  }

  private addSpinnerBtnClass(el: HTMLElement) {
    el.classList.add(this.spinnerConfig.BUTTON_CSS);
  }

  private removeSpinnerBtnClass(el: HTMLElement) {
    el.classList.remove(this.spinnerConfig.BUTTON_CSS);
  }

  private removeSpinnerGlobalClass(el: HTMLElement) {
    el.classList.remove(this.spinnerConfig.GLOBAL_CSS);
  }

  private btnEnable(btnEl: HTMLElement) {
    btnEl.removeAttribute('disabled');
  }

  private btnDisable(el: HTMLElement) {
    el.setAttribute('disabled', 'disabled');
  }

  private removeSpinnerClass(el: HTMLElement) {
    if (el instanceof HTMLButtonElement) {
      this.removeSpinnerBtnClass(el);
      this.btnEnable(el);
    } else {
      this.removeSpinnerGlobalClass(el);
    }
    this.showSpinner = false;
  }
}
