import {Component, Inject, OnInit} from '@angular/core';
import {Notification} from '@app/shared/notifier/model/notification';
import {NotifierService} from '@app/shared/notifier/services/notifier.service';
import {NOTIFIER_MODULE_CONFIG, NotifierModuleConfig} from '@app/shared/notifier/notifier.module.config';
import * as _ from 'lodash';

@Component({
  selector: 'dgl-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss']
})
export class NotifierComponent implements OnInit {
  notifications = new Array<Notification>();

  constructor(private notifierService: NotifierService, @Inject(NOTIFIER_MODULE_CONFIG) private notifierModuleConfig: NotifierModuleConfig) {
  }

  ngOnInit() {
    this.notifierService.onNotify((notification: Notification) => {
      this.addNotification(notification);
    });

    this.notifierService.onReset(() => {
      this.notifications = new Array<Notification>();
    });
  }

  removeNotification(notification: Notification): void {
    const notificationIndexToRemove = this.notifications.indexOf(notification);
    if (notificationIndexToRemove > -1) {
      this.notifications.splice(notificationIndexToRemove, 1);
    }
  }

  private addNotification(notifyMessage) {
    if (!this.isMessageTypeAlreadyAdded(notifyMessage)) {
      if (this.notifications.length === this.notifierModuleConfig.MAXIMUM_ALERTS_SHOW) {
        this.notifications.shift();
      }
      this.notifications.push(notifyMessage);
    }
  }

  private isMessageTypeAlreadyAdded(notifyMessage) {
    const notificationIndex = _.findIndex(this.notifications, (notification) => {
      return notifyMessage.type === notification.type && notifyMessage.message === notification.message;
    });
    return notificationIndex > -1;
  }

}
