import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {

  private _onRefresh$: Subject<void> = new Subject();

  constructor() {
  }

  onRefresh(): Observable<void> {
    return this._onRefresh$.asObservable();
  }

  refresh(): void {
    this._onRefresh$.next();
  }

}
