import {GoogleTagManagerSettings, PageTrackingSettings} from 'angulartics2';
import {InjectionToken} from '@angular/core';
import {Angulartics2Settings} from 'angulartics2/angulartics2-config';

export class AnalyticsModuleConfig {
  angulartics: {
    pageTracking: Partial<PageTrackingSettings>
    gtm: Partial<GoogleTagManagerSettings>
    settings: Partial<Angulartics2Settings>
  };
  gtm: {
    events: any;
    actions: any;
    categories: any;
    labels: any;
    virtualPagePaths: any;
  };
}

export const ANALYTICS_MODULE_DI_CONFIG: AnalyticsModuleConfig = {
  angulartics: {
    settings: {
      pageTracking: {},
      gtm: {}
    },
    pageTracking: {
      autoTrackVirtualPages: true,
      clearIds: false
    },
    gtm: {}
  },
  gtm: {
    events: {
      DEFAULT_EVENT: 'dsglEvent',
      DSGL_EVENT: 'dsglEvent',
      PAGE_VIEW_EVENT: 'pageView',
      VIRTUAL_PAGE_VIEW_EVENT: 'virtualPageView'
    },
    actions: {
      DEFAULT_ACTION: 'Click',
      CLICK_AVAILABLE: 'Click_Available',
      CLICK_CATEGORY: 'Click_Category',
      CLICK_FAMILY: 'Click_Family',
      CLICK_GROUP: 'Click_Group',
      CLICK_KPI: 'Click_KPI',
      CLICK_OA: 'Click_OA',
      CLICK_ORDER_DETAIL: 'Click_Order_Detail',
      CLICK_PRODUCT: 'Click_Product',
      CLICK_PRODUCT_DETAIL: 'Click_Product_Detail',
      CLICK_REFRESH: 'Click_Refresh',
      CLICK_CLOSE_DIALOG: 'Click_Return',
      CLICK_PRODUCT_COLOR_GROUP: 'Click_Subgroup',
      CLICK_VIEW: 'Click_View'
    },
    categories: {
      DEFAULT_CATEGORY: '',
      FILTER_VIEW: 'Filter-view',
      KPI: 'KPI',
      OA: 'OA',
      ORDER_DETAIL: 'Order-detail',
      PRODUCT_DETAIL: 'Product-detail',
      PRODUCT_VIEW: 'Product-view',
      REVIEW_ORDER: 'Review-order',
      SEARCH_ORDER: 'Search-order',
      SUBGROUP_VIEW: 'Subgroup-view'
    },
    labels: {
      EMPTY: '',
      ENABLE: 'Enable-',
      DISABLE: 'Disable-',
      ALL: 'All',
      ACTIVATE: 'Activate',
      RESTORE: 'Restore',
      OPEN: 'Open',
      CLOSE: 'Close',
      CLIENT: 'Cliente',
      AGENT: 'Agente',
      CENTRAL: 'Central'
    },
    virtualPagePaths: {
      PDP: 'vpv/pdp',
      GROUP_PRODUCT_COLOR: 'vpv/group'
    },
  }
};

export let ANALYTICS_MODULE_CONFIG = new InjectionToken<AnalyticsModuleConfig>('analytics.module.config');
