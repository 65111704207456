import {ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CORE_MODULE_CONFIG, CORE_MODULE_CONSTANTS} from '@app/core/core.module.config';
import {AuthenticationModule} from '@app/core/authentication/authentication.module';
import {InterceptorModule} from '@app/core/interceptor/interceptor.module';
import {NotifierModule} from '@app/shared/notifier/notifier.module';
import {DglErrorHandlerService} from '@app/core/services/dgl-error-handler.service';
import {LanguageModule} from '@app/shared/language/language.module';
import {SpinnerModule} from '@app/shared/spinner/spinner.module';
import {DialogModule} from '@app/shared/dialogs/dialog.module';
import {CookieService} from 'ngx-cookie-service';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {AnalyticsModule} from '@app/shared/analytics/analytics.module';

export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    {prefix: CORE_MODULE_CONSTANTS.TRANSLATE_CONFIG.I18N_PATH, suffix: CORE_MODULE_CONSTANTS.TRANSLATE_CONFIG.SUFFIX_FILE},
    {prefix: CORE_MODULE_CONSTANTS.TRANSLATE_CONFIG.I18N_PATH_WIDGET_MY_ANALYSIS, suffix: CORE_MODULE_CONSTANTS.TRANSLATE_CONFIG.SUFFIX_FILE},
  ]);
}

@NgModule({
  imports: [
    CommonModule,
    NgxWebstorageModule.forRoot(CORE_MODULE_CONSTANTS.WEBSTORAGE_CONFIG),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AuthenticationModule.forRoot(),
    NotifierModule.forRoot(),
    LanguageModule.forRoot(),
    InterceptorModule.forRoot(),
    SpinnerModule.forRoot(),
    DialogModule.forRoot(),
    AnalyticsModule.forRoot()
  ],
  declarations: [],
  providers: [
    {provide: CORE_MODULE_CONFIG, useValue: CORE_MODULE_CONSTANTS},
    {provide: ErrorHandler, useClass: DglErrorHandlerService},
    CookieService
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
