import {ModuleWithProviders, NgModule} from '@angular/core';
import {AuthGuard} from '@app/core/authentication/guard/logged/auth.guard';
import {AuthGuestGuard} from '@app/core/authentication/guard/guest/auth.guest.guard';
import {AuthHttp} from '@app/core/authentication/auth-http/auth-http.service';
import {AuthenticationService} from '@app/core/authentication/auth/authentication.service';
import {AuthenticationRemote} from '@app/core/authentication/auth/authentication.remote';
import {
  AUTHENTICATION_MODULE_CONFIG, BASIC_AUTHENTICATION_MODULE_CONSTANTS
} from '@app/core/authentication/authentication.module.config';
import {BasicAuthHttp} from '@app/core/authentication/basic/auth-http/basic-auth-http.service';
import {BasicAuthenticationRemote} from '@app/core/authentication/basic/auth/basic-authentication.remote';
import {BasicAuthenticationService} from '@app/core/authentication/basic/auth/basic-authentication.service';


@NgModule({
  imports: [],
  declarations: [],
  providers: []
})
export class AuthenticationModule {
  public static forRoot(): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        AuthGuard,
        AuthGuestGuard,
        BasicAuthHttp,
        BasicAuthenticationRemote,
        BasicAuthenticationService,
        {provide: AuthHttp, useClass: BasicAuthHttp},
        {provide: AuthenticationRemote, useClass: BasicAuthenticationRemote},
        {provide: AuthenticationService, useClass: BasicAuthenticationService},
        {provide: AUTHENTICATION_MODULE_CONFIG, useValue: BASIC_AUTHENTICATION_MODULE_CONSTANTS}
      ]
    };
  }
}
