export const environment = {
  production: true,
  hmr: false,
  gtm: {
    active: true,
    type: 'text/javascript',
    id: 'GTM-54LCDW3',
    src:
    '  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
    '    new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n' +
    '    j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
    '    \'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n' +
    '  })(window,document,\'script\',\'dataLayer\',\'GTM-54LCDW3\');'
  },
  BASE_URL_OA: '/occ/v2/dsglb2b/oa/',
  BASE_URL_ROOT: '/'
};
