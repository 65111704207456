import {enableProdMode, isDevMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from '@app/app.module';
import {environment} from '@env/environment';

import {hmrBootstrap} from './hmr';

if (environment.production) {
  enableProdMode();
}

if (isDevMode()) {
  console.log('! RUNNING APPLICATION IN DEVELOPMENT MODE');
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.gtm.active) {
  const script = document.createElement('script');
  script.type = environment.gtm.type;
  script.innerHTML = environment.gtm.src;
  document.getElementsByTagName('head')[0].appendChild(script);
}

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap()
    .catch(err => console.log(err));
}
