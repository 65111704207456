import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'dgl-form-messages',
  templateUrl: './form-messages.component.html',
  styleUrls: ['./form-messages.component.scss']
})
export class FormMessagesComponent {

  @Input()
  inputForm: FormControl;

  @Input()
  checkPristine = true;

  constructor() { }
}
