import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorage} from 'ngx-webstorage';
import {
  LANGUAGE_MODULE_CONFIG,
  LANGUAGE_MODULE_CONSTANTS,
  LanguageModuleConfig
} from '@app/shared/language/language.module.config';
import {LanguageRemote} from '@app/shared/language/services/language.remote';
import {LanguageState} from '@app/shared/language/model/language.state';
import {Observable} from 'rxjs/index';
import {Language} from '@app/shared/language/model/language';

@Injectable()
export class LanguageService {

  @LocalStorage(LANGUAGE_MODULE_CONSTANTS.CURRENT_TOKEN_KEY)
  localStorageCurrentLanguage;

  constructor(private translateService: TranslateService,
              private languageRemote: LanguageRemote,
              private languageState: LanguageState,
              @Inject(LANGUAGE_MODULE_CONFIG) private languageModuleConfig: LanguageModuleConfig) {
  }

  public initLanguages() {
    const initLanguagesSubscription = this.languageRemote.getLanguages().subscribe((languages: Language[]) => {
      this.languageState.availableLanguages = languages;
      this.translateService.addLangs(languages.map(language => language.id));
      this.setDefault();
      if (initLanguagesSubscription) {
        initLanguagesSubscription.unsubscribe();
      }
    });
  }

  private setDefault() {
    if (!this.translateService.getDefaultLang()) {
      this.translateService.setDefaultLang(this.languageModuleConfig.DEFAULT_LANGUAGE);
    }
    const languageId = this.languageState.isValidLanguage(this.localStorageCurrentLanguage) ? this.localStorageCurrentLanguage : this.languageModuleConfig.DEFAULT_LANGUAGE;
    this.setAppLanguage(languageId);
  }

  public getAvailableLanguages(): Observable<Language[]> {
    return this.languageState.availableLanguages$;
  }

  public change(languageId: string) {
    if (this.translateService.getLangs().length === 0 || this.languageState.isValidLanguage(languageId)) {
      this.setAppLanguage(languageId);
    }
  }

  private setAppLanguage(languageId: string) {
    this.translateService.use(languageId);
    this.localStorageCurrentLanguage = languageId;
    this.languageState.currentLanguageId = languageId;
  }

  public getCurrentLanguageId(): Observable<string> {
    return this.languageState.currentLanguageId$;
  }
}
