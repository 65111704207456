import {ModuleWithProviders, NgModule} from '@angular/core';
import {NotifierComponent} from './components/notifier/notifier.component';
import {NotificationComponent} from './components/notification/notification.component';
import {CommonModule} from '@angular/common';
import {NotifierService} from '@app/shared/notifier/services/notifier.service';
import {NOTIFIER_MODULE_CONFIG, NOTIFIER_MODULE_CONSTANTS} from '@app/shared/notifier/notifier.module.config';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [NotifierComponent, NotificationComponent],
  exports: [NotifierComponent]
})
export class NotifierModule {
  public static forRoot(): ModuleWithProviders<NotifierModule> {
    return {
      ngModule: NotifierModule,
      providers: [
        {provide: NOTIFIER_MODULE_CONFIG, useValue: NOTIFIER_MODULE_CONSTANTS},
        NotifierService
      ]
    };
  }
}
