import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {throwError, Observable, of} from 'rxjs';
import {INTERCEPTOR_MODULE_CONFIG, InterceptorModuleConfig} from '@app/core/interceptor/interceptor.module.config';
import {AuthenticationService} from '@app/core/authentication';
import {APP_CONFIG, AppConfig} from '@app/app.config';
import {NotifierService} from '@app/shared/notifier/services/notifier.service';

@Injectable()
export class HttpInterceptorHandlerService {

  constructor(private route: Router,
              private authenticationService: AuthenticationService,
              private notifierService: NotifierService,
              @Inject(INTERCEPTOR_MODULE_CONFIG) private interceptorModuleConfig: InterceptorModuleConfig,
              @Inject(APP_CONFIG) private appConfig: AppConfig) {
  }

  manageSuccess(evt) {
    if (this.isResponseSuccess(evt)) {
      this.notifierService.notifyReset();
    }
  }

  manageErrors(error: HttpErrorResponse): Observable<any> {
    if (this.isAuthError(error)) {
      this.authenticationService.logout();
      if (this.route.url !== this.appConfig.ROUTING.PATH.AUTH_ERROR_REDIRECTION) {
        this.route.navigate([this.appConfig.ROUTING.PATH.AUTH_ERROR_REDIRECTION]);
      } else {
        this.notifierService.notifyError(this.interceptorModuleConfig.MESSAGE_KEYS.LOGIN_WRONG_CREDENTIALS_KEY);
      }
    }
    if (this.isServerError(error) || this.isBadRequestError(error)) {
      this.notifierService.notifyError(this.interceptorModuleConfig.MESSAGE_KEYS.GLOBAL_ERROR_KEY);
    }
    if (this.isParsingBodyError(error)) {
      return of(error);
    } else {
      return throwError(error);
    }
  }

  private isResponseSuccess(evt) {
    return evt instanceof HttpResponse && evt.status === this.interceptorModuleConfig.HTTP_STATUS.SUCCESS_STATUS_CODE;
  }

  private isAuthError(evt) {
    return evt instanceof HttpErrorResponse && this.interceptorModuleConfig.HTTP_STATUS.AUTH_ERROR_STATUS_CODES.indexOf(evt.status) >= 0;
  }

  private isServerError(evt) {
    return evt instanceof HttpErrorResponse && evt.status >= this.interceptorModuleConfig.HTTP_STATUS.INTERNAL_SERVER_ERROR_STATUS_CODE;
  }

  private isNotStockError(evt) {
    return evt instanceof HttpErrorResponse && evt.status === this.interceptorModuleConfig.HTTP_STATUS.NO_STOCK_ERROR_CODE;
  }

  private isBadRequestError(evt) {
    return evt instanceof HttpErrorResponse && evt.status === this.interceptorModuleConfig.HTTP_STATUS.BAD_REQUEST_ERROR_CODE;
  }

  private isParsingBodyError(error: HttpErrorResponse): boolean {
    return error.status === this.interceptorModuleConfig.HTTP_STATUS.SUCCESS_STATUS_CODE;
  }
}
